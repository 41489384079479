@import styles/_variables

.wrapper
  margin: 2em auto
  max-width: 500px

  p
    font-size: .875em
    text-align: center

.header
  align-items: center
  color: #999
  display: flex
  font-size: 2em
  justify-content: center
  margin-bottom: 1em

.icon
  color: $fc-color-soft-green
  font-size: 2.5em
  margin-right: .25em
