.header
  background-color: #fff
  box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.1)
  left: 0
  position: fixed
  right: 0
  top: 0
  width: 100%
  z-index: 100

.headerScrolled
  position: fixed

.top
  align-items: center
  display: flex
  flex-direction: row
  justify-content: space-between
  padding-top: 10px
  padding-bottom: 10px
  position: relative

@media all and (min-width: 768px)
  .top
    padding-top: 20px
    padding-bottom: 0px

    & > :global(.menu-button)
      display: none

.bottom
  align-items: center
  display: flex
  flex-direction: row
  justify-content: space-between

// ---------------------------------------------
// Logo
// ---------------------------------------------

.logo
  background-image: url('~assets/images/logo-240x47.jpg')
  background-repeat: none
  background-size: cover
  display: block
  height: 31px
  width: 160px

@media all and (min-width: 768px)

  .headerScrolled .logo
    background-image: url('~assets/images/logo-240x47.jpg')
    width: 240px
    height: 47px

  .logo
    background-image: url('~assets/images/logo-380x75.jpg')
    width: 380px
    height: 75px


