@import styles/_variables

.button
  align-items: center
  background-color: #fff
  border: 0
  border-bottom: 1px solid lighten($fc-color-light-salmon, 10%)
  color: #666
  display: flex
  font-family: $font-primary
  flex: 1
  flex-direction: column
  font-size: 1em
  justify-content: center
  outline: none
  padding: 32px 16px
  text-align: center

  &:last-child
    border-bottom: 0

  &:hover
    background-color: lighten($fc-color-brownie-gray, 10%)
    cursor: pointer
    text-decoration: none

.icon
  align-items: center
  background-color: $fc-color-light-salmon
  border-radius: 40px
  color: #fff
  display: flex
  font-size: 1.5em
  height: 80px
  justify-content: center
  width: 80px

.title
  color: #666
  font-size: 1em
  font-style: normal
  font-weight: 300
  letter-spacing: .05em
  line-height: 1.25em
  margin: 1em 0 .75em 0
  text-transform: uppercase

.text
  color: #999
  font-style: normal
  font-size: .75em
  margin: 0
  max-width: 60%

@media all and (min-width: 768px)
  .button
    border-bottom: 0
    border-right: 1px solid lighten($fc-color-light-salmon, 10%)
    justify-content: flex-start

    &:last-child
      border-right: 0

  .icon
    border-radius: 25px
    font-size: 1.25em
    height: 50px
    width: 50px

  .text
    max-width: 90%
