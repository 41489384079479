@import styles/_variables

.badge
  background-image: url('~assets/images/la_badge.png')
  background-repeat: none
  background-size: cover
  bottom: -45px
  display: block
  height: 45px
  position: absolute
  right: -7px
  width: 75px

@media all and (min-width: map-get($grid-breakpoints, "md"))
  .badge
    height: 60px
    right: -12px
    top: 0
    width: 100px

@media all and (min-width: map-get($grid-breakpoints, "lg"))
  .badge
    height: 90px
    right: -12px
    top: 0
    width: 150px
