@import styles/_variables

.containerVideo
  align-items: center
  display: flex
  justify-content: center

.containerVideoActions
  align-items: center
  display: flex
  justify-content: center

.containerVideo
  iframe
    height: 200px

@media all and (min-width: 350px)
  .containerVideo
    iframe
      height: 250px

@media all and (min-width: 470px)
  .containerVideo
    iframe
      height: 300px

@media all and (min-width: map-get($grid-breakpoints, "sm"))
  .containerVideo
    iframe
      height: 310px

@media all and (min-width: map-get($grid-breakpoints, "md"))
  .containerVideo
    iframe
      height: 390px

@media all and (min-width: map-get($grid-breakpoints, "lg"))
  .containerVideo
    iframe
      height: 540px

.cardsRow
  align-items: stretch
  display: flex
  flex-direction: column
  justify-content: flex-start

  :global(.card)
    margin-bottom: 16px

    &:last
      margin-bottom: 0

@media all and (min-width: 768px)
  .cardsRow
    flex-direction: row
    justify-content: space-between

    :global(.card)
      flex: 1
      margin-right: 16px

      &:last-child
        margin-right: 0

@media all and (min-width: 992px)
  .cardsRow
    :global(.card)
      margin-right: 24px

      &:last-child
        margin-right: 0
