.note,
.subNote
  margin-bottom: 0
  text-align: left

.note
  color: #444
  font-size: .875em
  margin-top: 2em

.subNote
  color: #666
  font-size: .75em
  margin-top: 0

.message
  margin-top: 0
