.card
  align-items: stretch
  display: flex
  flex-direction: column
  justify-content: flex-start

@media all and (min-width: 768px)
  .card
    flex-direction: row
    justify-content: space-between
