.button
  align-items: center
  background-color: transparent
  border: none
  border-radius: .25em
  color: #000
  display: flex
  font-size: 1.75em
  height: 1.75em
  justify-content: center
  width: 1.75em

  svg
    width: 50px
