.button
  align-items: center
  background-color: transparent
  border: none
  border-bottom: 1px solid #f0f0f0
  color: #999
  display: flex
  font-size: .875em
  padding: 16px

  & :global(svg)
    margin-right: 8px
